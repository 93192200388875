<template>
    <div>
        <comp-form ref="form"></comp-form>
        <comp-table title="选品分组" ref="comp_table" :columns="columns" table-api="/gateway/apps/o2o/api/o2o/platform/goods/listCategoryGoodsByPage">
            <template #operate>
                <Button type="primary" @click="$refs.form.open()">新增</Button>
            </template>
        </comp-table>
    </div>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import CompTable from "../../../jointly/components/comp-table.vue"
import CompForm from "./form.vue"

export default {
    components: { CompTable, CompForm },

    data() {
        return {
            columns: [
                {
                    title: "分组名称",
                    key: "categoryName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "商品数量",
                    key: "goodsCount",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "添加时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    float: "right",
                    width: 300,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.form.open(params.row.categoryId, params.row)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/shopselectiongroupgoods", {
                                                c: params.row.categoryId,
                                            })
                                        },
                                    },
                                },
                                "商品明细"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/shopselectiongoods", {
                                                c: params.row.categoryId,
                                            })
                                        },
                                    },
                                },
                                "添加选品"
                            ),
                        ])
                    },
                },
            ],
        }
    },
}
</script>
<style lang="less"></style>
